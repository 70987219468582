import 'react-phone-number-input/style.css'
import React from 'react';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import { YMInitializer } from 'react-yandex-metrika';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import TagManager from 'react-gtm-module'

export const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 480px)").matches;

class App extends React.Component{

	constructor(props) {
		super(props);

		this.state = {
			config: null,
			email: '',
			emailIsInvalid: false,
			phone: '',
			phoneIsInvalid: false
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputPhChange = this.handleInputPhChange.bind(this);
	}

	componentDidMount() {

		const pathComponents = window.location.pathname.split('/');

		axios.get(process.env.REACT_APP_API_URL + `landing/` + pathComponents[pathComponents.length-1])
			.then(res => {
				const config = res.data;
				this.setState({ config });
				if (config.analytics != null) {
					if (config.analytics.fb != null) {
						this.installFBPixel(config.analytics.fb);
					}
					if (config.analytics.vk != null) {
						this.installVKPixel(config.analytics.vk);
					}
					if (config.analytics.gtm) {
						TagManager.initialize({gtmId: config.analytics.gtm})
					}
				}
				document.title = config.title;
			})
			.catch(err => {
				if (err.response.status === 404) {
					window.location.href = process.env.REACT_APP_NOT_FOUND_URL;
				}
			})
	}

	installVKPixel(pixelId) {
		const t = document.createElement('script');
		t.type = 'text/javascript';
		t.async = true;
		t.src = 'https://vk.com/js/api/openapi.js?168';

		t.onload = () => {
			// eslint-disable-next-line no-undef
			VK.Retargeting.Init(pixelId);
			// eslint-disable-next-line no-undef
			VK.Retargeting.Hit();
		};

		document.head.appendChild(t);
	}

	installFBPixel(pixelId) {
		const options = {
			autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
			debug: false, // enable logs
		};
		ReactPixel.init(pixelId, null, options);
	}

	handleInputChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});

		if (event.target.name === 'email') {
			this.setState({
				emailIsInvalid: false
			});
		}
	}

	handleInputPhChange(event) {
		this.setState({
			phone: event
		});
		this.setState({
			phoneIsInvalid: false
		});
	}

	async handleMessengerClick(botId, e) {
		let botConfig = this.state.config.bots.filter(b => b.id === botId)[0];

		if (this.state.config.collect_phone && !isValidPhoneNumber(this.state.phone)) {
			this.setState({
				phoneIsInvalid: true
			});
			return;
		} else {
			this.setState({
				phoneIsInvalid: false
			});
		}

		if (this.state.config.collect_email && !this.validateEmail(this.state.email)) {
			this.setState({
				emailIsInvalid: true
			});
			return;
		} else {
			this.setState({
				emailIsInvalid: false
			});
		}

		let messengerData = this.state.config.tag + '_' + this.state.config.cf_id;

		if (this.state.config.collect_email || this.state.config.collect_phone) {
			try {
				let resp = await axios.post(process.env.REACT_APP_API_URL + `form`, {
					idLanding: this.state.config.cf_id,
					email: this.state.email,
					phone: this.state.phone
				});

				messengerData += '_' + resp.data.id;
			}
			catch (eX) {
				console.error(eX);
			}
		}

		window.location.href = await this.getMessengerUrl(botConfig.type, botConfig.group_key, messengerData);
	}

	validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	async getMessengerUrl(type, messengerGroup, data) {

		let result = '';

		switch (type) {
			case 'vk':
				result = `https://vk.me/public${messengerGroup}?ref=`;
				break;
			case 'messenger':
				result = `https://m.me/${messengerGroup}?ref=`;
				break;
			case 'telegram':
				result = `https://t.me/${messengerGroup}?start=`;
				break;
			case 'viber':
				result = `viber://pa?chatURI=${messengerGroup}&context=`;
				break;
			default:
				throw new Error('Messenger type not supported');
		}

		result += data;

		// if (type === 'viber') {
		// 	let resp = await axios.get(process.env.REACT_APP_API_URL + `shortUrl/`+encodeURIComponent(result));
		// 	return resp.data;
		// }

		return result;
	}

	getImageUrl() {
		if (this.state.config.preview_image == null) {
			return null;
		}

		try {
			const url = new URL(this.state.config.preview_image.filepath);
			if (url) {
				return this.state.config.preview_image.filepath;
			}
		} catch (error) {
			return process.env.REACT_APP_IMAGES_SERVER_BASE_URL + this.state.config.preview_image.filepath;
		}
	}

	render() {
		if (this.state.config == null) {
			return null
		}

		const config = this.state.config;

		let mediaBlock = <div className="landing__logotype" />;

		if (config.preview_video != null && config.preview_video.match(/HTTP/ig) !== null) {
			const preview_video = `${config.preview_video}?autoplay=1`;
			mediaBlock = <div className="landing__video">
				<div className="landing__video-holder">
					<iframe className="landing__video-frame" src={preview_video} title="Video"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen />
				</div>
			</div>;
		}
		else if (config.preview_image != null && config.preview_image.filepath !== "") {
			const preview_image = this.getImageUrl();
			if (preview_image.match(/\.(mp4|3g2|3gp|avi|flv|m4v|mkv|mov|mpeg|mpg|swf|webm|wmv|yuv])$/ig) !== null) {
				mediaBlock = <div className="landing__video">
					<div className="landing__video-holder">
						<iframe className="landing__video-frame" src={preview_image} title="Video"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen />
					</div>
				</div>;
			} else {
				mediaBlock = <img className="landing__img" src={preview_image} alt="" />
			}
		}

		let countConstLabels = 0;
		config.bots.forEach(function(bot, i, arr)  {
			if ( (bot.start_button_title.toLowerCase() === bot.type.toLowerCase() && bot.type !== 'messenger') ||
				(bot.type === 'messenger' && bot.start_button_title === 'FB Messenger')
			) {
				countConstLabels += 1;
			}
		});

		const constLabels = (countConstLabels === config.bots.length) ? true : false;

		return (
			<div className="landing">
				{config.analytics != null && config.analytics.yandex != null &&
					<YMInitializer accounts={[Number(config.analytics.yandex)]} />
				}
				<div className="landing__holder">
					<div className="landing__head">{mediaBlock}</div>
					<div className="landing__title">{config.title}</div>
					<div style={{whiteSpace: 'pre-wrap'}} className="landing__content">{config.description}</div>
					<div className="landing__form">
						{config.collect_phone &&
							<div className="landing__form-field">
								<div className='input_phone'>Номер телефона
									<PhoneInput
										className="landing__form-input"
										type="tel"
										name="phone"
										defaultCountry="RU"
										placeholder="(999) 999-99-99"
										value={this.state.phone}
										onChange={this.handleInputPhChange}/>
								</div>
								{this.state.phoneIsInvalid &&
									<div className="landing__form-error">Указан некорректный Телефон</div>
								}

							</div>
						}
						{config.collect_email &&
							<div className="landing__form-field">
								<div className='input_email'>Почта
									<input
										className="landing__form-input"
										type="email"
										name="email"
										placeholder="Введите E-mail"
										value={this.state.email}
										onChange={this.handleInputChange}
									/> </div>
								{this.state.emailIsInvalid &&
									<div className="landing__form-error">Указан некорректный Email</div>
								}
							</div>
						}
					</div>
					<div className={"landing__messengers " + ((isMobile() && constLabels) ? (config.bots.length === 2 ? 'mobile mobile_2' : 'mobile') : (config.bots.length > 1 ? 'landing__messengers_'+config.bots.length : ''))}>
						{config.bots.map((bot) =>
							<div
								key={bot.id}
								className={"landing__messengers-item landing__messengers-item_"+bot.type}
								onClick={(e) => this.handleMessengerClick(bot.id, e)}
							>{!(constLabels) &&
								`${bot.start_button_title}`
							}</div>
						)}
					</div>
					<div className="landing__policy">
						При заполнении своих данных вы автоматически соглашаетесь с <a href="https://www.chatforma.com/policy" target="_blank" rel="noreferrer">политикой конфиденциальности</a>
					</div>
					<div className="landing__production">
						Сделано в <a href="https://www.chatforma.com" target="_blank" rel="noreferrer">Chatforma</a>
					</div>
				</div>
			</div>
		);
	}
}

export default App;
